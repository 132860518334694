import { useContext, useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import SubmissionFormContext from '../../../context/SubmissionFormContext';
import ResultsContext from '../../../context/ResultsContext';
import ToastContext from '../../../context/ToastContext';
import Loader from '../../Loader/Loader';
import SubmissionFormBasic from './SubmissionFormBasic';
import {
  calcServiceLife,
  calcCyclesPerYear,
  checkNumDecimalConditions
} from '../../../helpers/formCalculations';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import teraStor from '../../../images/AESI-TeraStor-72dpi-Doors.png';
import teraStorClosed from '../../../images/terastor-closed.png';
import AESI4PDF from '../../../images/AESI-2-logo.png';
import './SubmissionForm.scss';

export default function SubmissionForm({
  showResults,
  setShowResults,
  showConfig,
  setShowConfig,
  setClosedContainer,
  closedContainer,
  showOverlay,
  setShowOverlay
}) {

  const REACT_APP_PROD_URL = process.env.REACT_APP_PROD_URL;
  const DEVELOPMENT_API = process.env.REACT_APP_DEVELOPMENT_API;
  const REACT_APP_PROD_API = process.env.REACT_APP_PROD_API;
  const REACT_APP_UAT_API = process.env.REACT_APP_UAT_API;
  const REACT_APP_UAT_URL = process.env.REACT_APP_UAT_URL;
  const REACT_APP_VIZ_UAT_URL = process.env.REACT_APP_VIZ_UAT_URL;

  const [API, setAPI] = useState(DEVELOPMENT_API);
  const { formContent, updateFormContent } = useContext(SubmissionFormContext);
  const { toastContent, updateToastContent } = useContext(ToastContext);
  const [formData, setFormData] = useState(formContent);
  const [submittedData, setSubmittedData] = useState();
  const { resultsContent, updateResultsContent } = useContext(ResultsContext);
  const [loading, setLoading] = useState(false);
  const [changedField, setChangedField] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const params = {
    // form params
    "RSP": searchParams.get("rsp"),
    "EC": searchParams.get("ec"),
    "DD": +searchParams.get("ec") / +searchParams.get("rsp"),
    "ECR": searchParams.get("ecr"),
    "AUG": searchParams.get("aug"),
    "IA": searchParams.get("ia"),
    "AP": searchParams.get("ap"),
    "CPY": searchParams.get("cpy"),
    "PFP": searchParams.get("pfp"),
    "SL": searchParams.get("sl"),
    "POIV": searchParams.get("poiv"),
    "DATE": searchParams.get("date"),
    // visualization params
    "visualizationParams": {
      "apv-env": searchParams.get("apv-env"),
      "apv-area": searchParams.get("apv-area"),
      "apv-density": searchParams.get("apv-density"),
      "apv-augmentation": searchParams.get("apv-augmentation"),
      "apv-cam-pos": searchParams.get("apv-cam-pos"),
      "apv-cam-target": searchParams.get("apv-cam-target"),
      "apv-cam-rot": searchParams.get("apv-cam-rot"),
    },
  };
  console.log(params);

  // set the proper api URL and query params
  useEffect(() => {
    // API URL
    if (window.location.host === REACT_APP_PROD_URL) {
      setAPI(REACT_APP_PROD_API)
    } else if (window.location.host === REACT_APP_UAT_URL) {
      setAPI(REACT_APP_UAT_API)
    } else if (window.location.host === REACT_APP_VIZ_UAT_URL) {
      setAPI(REACT_APP_UAT_API)
    }

    // QUERY PARAMS
    if (searchParams.size > 0) {
      let default_date = new Date();
      default_date.setDate(new Date().getDate() + 28 * 7)

      setFormData({
        rated_system_power_mw: +params.RSP ? +params.RSP : 100,
        power_factor_support_at_poi: +params.PFP ? +params.PFP : 0.95,
        bol_eol_flag: params.ECR == "BOL" ? "BOL" : "EOL",
        desired_discharge_energy_mwh: +params.EC ? +params.EC : 400,
        cycles_per_year: +params.CPY ? +params.CPY : 365,
        service_life: +params.SL ? +params.SL : 20,
        inverter: "AESI",
        discharge_duration: +params.DD ? parseFloat(+params.DD).toFixed(2) : 4,
        augmentation: params.AUG == "false" ? false : true,
        initial_augmentation_period: +params.IA ? +params.IA : 1,
        augmentation_period: +params.AP ? +params.AP : 3,
        delivery_date: params.DATE ? params.DATE : default_date.toISOString().slice(0, 10),
        poi_voltage: +params.POIV ? +params.POIV : 34.5,
      });
      setTimeout(() => {
        getSystemData();
      }, 500)
    }
  }, [REACT_APP_PROD_URL, REACT_APP_UAT_URL, REACT_APP_PROD_API, REACT_APP_UAT_API])


  useEffect(() => {
    function compareObjects(obj1, obj2) {
      // Step 1: Check if both objects have the same number of keys
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
        return false;
      }
      // Step 2: Iterate through one object and compare keys and values
      for (let key of keys1) {
        if (!obj2.hasOwnProperty(key)) {
          // If the other object doesn't have the same key, return false
          return false;
        }
        if (obj1[key] !== obj2[key]) {
          // If the values for the same key are different, return false
          return false;
        }
      }
      // Step 3: All keys and values match, return true
      return true;
    }

    if (submittedData) {
      if (compareObjects(formData, submittedData)) {
        setShowOverlay(false);
      } else {
        setShowOverlay(true);
      }
    }
  }, [formData])


  // form handler for updating state of inputs and selects
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setChangedField({ name: name, value: value });
    let parsedValue = value;

    if (type.includes("select")) {
      setFormData(data => ({
        ...data,
        [name]: parsedValue
      }))
      if (!showResults) {
        setClosedContainer(parsedValue === "AESI" ? false : true)
      }
      return;
    }

    if (type === "date") {
      setFormData(data => ({
        ...data,
        [name]: value
      }))
      return;
    }

    // check for decimals in appropriate fields and ensure is a number
    if (!checkNumDecimalConditions(name, parsedValue)) {
      return;
    }


    // turn color black
    e.target.classList.remove("unedited");

    // DD cannot exceed 24
    if (name === "discharge_duration" && value > 24) {
      return;
    }

    const regEx = /^\d+\.\d{3,}$/;
    if ((name === "discharge_duration" || name === "desired_discharge_energy_mwh" || name === "rated_system_power_mw") && regEx.test(value.toString())) {
      parsedValue = parseFloat(value).toFixed(2);
    }

    if (!value.includes(".") && value !== "") {
      parsedValue = +parsedValue;
    }

    if (name === "cycles_per_year") {
      parsedValue = calcCyclesPerYear(parsedValue)
    }
    else if (name === "service_life") {
      parsedValue = calcServiceLife(parsedValue)
    }
    setFormData(data => ({
      ...data,
      [name]: parsedValue
    }))
  }

  function handleBlur() {
    const name = changedField.name;
    if (!name) return;
    let value = formData[name]
    let energyCapacity;
    let dischargeDuration;
    let ratedSystemPower;

    setShowOverlay(true)

    // IF 0 don't do anything but clear the 0
    if (+value === 0) {
      setFormData(data => ({
        ...data,
        [name]: ""
      }))
    }

    // IF RSP IS CHANGED
    if (name === "rated_system_power_mw") {
      if (+value < 1 || value === "") {
        value = 1;
        setFormData(data => ({
          ...data,
          "rated_system_power_mw": value
        }))
      } else if (+value > 10000) {
        value = 10000;
        setFormData(data => ({
          ...data,
          "rated_system_power_mw": value
        }))
      }
      energyCapacity = +formData.discharge_duration * +value
      energyCapacity = parseFloat(energyCapacity).toFixed(2);
      // autoChanged.push("energyCapacity")
      setFormData(data => ({
        ...data,
        "desired_discharge_energy_mwh": energyCapacity
      }))
    }

    // IF DD IS CHANGED
    if (name === "discharge_duration") {
      if (+value < 2 || value === "") {
        value = 2;
        setFormData(data => ({
          ...data,
          "discharge_duration": value
        }))
      } else if (+value > 24) {
        value = 24;
        setFormData(data => ({
          ...data,
          "discharge_duration": value
        }))
      }
      energyCapacity = +formData.rated_system_power_mw * +value
      energyCapacity = parseFloat(energyCapacity).toFixed(2);
      // autoChanged.push("energyCapacity");
      setFormData(data => ({
        ...data,
        "desired_discharge_energy_mwh": energyCapacity
      }))
    }

    // IF EC IS CHANGED
    if (name === "desired_discharge_energy_mwh") {
      if (+value < 2 || value === "") {
        value = 2;
        setFormData(data => ({
          ...data,
          "desired_discharge_energy_mwh": value
        }))
      } else if (+value > 240000) {
        value = 240000;
        setFormData(data => ({
          ...data,
          "desired_discharge_energy_mwh": value
        }))
      }
      dischargeDuration = +value / +formData.rated_system_power_mw;
      // autoChanged.push("dischargeDuration");
      if (dischargeDuration < 2) {
        dischargeDuration = 2;
        ratedSystemPower = +value / dischargeDuration;
        // autoChanged.push("ratedSystemPower");
        setFormData(data => ({
          ...data,
          "rated_system_power_mw": ratedSystemPower,
        }))
      } else if (dischargeDuration > 24) {
        dischargeDuration = 24;
        ratedSystemPower = +value / dischargeDuration;
        // autoChanged.push("ratedSystemPower");
        setFormData(data => ({
          ...data,
          "rated_system_power_mw": ratedSystemPower,
        }))
      }

      dischargeDuration = parseFloat(dischargeDuration).toFixed(2);
      setFormData(data => ({
        ...data,
        "discharge_duration": dischargeDuration,
      }))
    }

    // ensure poi is not below .8
    if (name === "power_factor_support_at_poi" && value < .8) {
      setFormData(data => ({
        ...data,
        "power_factor_support_at_poi": .8,
      }))
    } else if (name === "power_factor_support_at_poi" && value > 1) {
      setFormData(data => ({
        ...data,
        "power_factor_support_at_poi": 1,
      }))
    }

    // ensure POI Voltage under 1000
    if (name === "poi_voltage" && value > 1000) {
      setFormData(data => ({
        ...data,
        "poi_voltage": 1000,
      }))
    }

    // initial augmentation should not be below service life
    if (
      (name === "initial_augmentation_period" && value > formData.service_life) ||
      (name === "service_life" && value < formData.initial_augmentation_period)
    ) {
      setFormData(data => ({
        ...data,
        "initial_augmentation_period": formData.service_life,
      }))
    }

    // augmentation should not be below service life
    if (
      (name === "augmentation_period" && value > formData.service_life) ||
      (name === "service_life" && value < formData.augmentation_period)
    ) {
      setFormData(data => ({
        ...data,
        "augmentation_period": formData.service_life,
      }))
    }

    setChangedField("");
  }

  function handleToggleChange(fEvent) {
    setShowOverlay(true);
    const { name, value } = fEvent
    setChangedField({ name: name, value: value });

    if (name === "augmentation") {
      if (value === false) {
        setFormData(data => ({
          ...data,
          [name]: value,
          "initial_augmentation_period": 0,
          "augmentation_period": 0
        }))
        return;
      } else if (value === true) {
        setFormData(data => ({
          ...data,
          [name]: value,
          "initial_augmentation_period": 1,
          "augmentation_period": 3
        }))
      }
    }
    setFormData(data => ({
      ...data,
      [name]: value
    }))
  }

  const getSystemData = (e) => {
    setShowOverlay(false);
    if (e) { e.preventDefault(); }
    setSubmittedData(formData);
    if (changedField === "rated_system_power_mw" ||
      changedField === "desired_discharge_energy_mwh" ||
      changedField === "discharge_duration") {
      handleBlur();
      return;
    }
    setLoading(true);
    let tempResultsContent = resultsContent;
    delete tempResultsContent["augmentation_summary"];
    updateResultsContent({
      ...tempResultsContent
    });
    updateFormContent(formData);
    let finalFormData = { ...formData };
    setClosedContainer(formData.inverter === "AESI" ? false : true);
    delete finalFormData.discharge_duration;
    for (let key in finalFormData) {
      if (finalFormData[key] === "")
        delete finalFormData[key];
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'gC0WrwRfg57dGcjlHIFEIaIfO0z4AsHe7KqVWacV'
      },
      body: JSON.stringify(finalFormData)
    };
    fetch(`${API}/calculate/basic`, requestOptions)
      .then(response => response.json())
      .then(data => {
        updateResultsContent({
          ...data,
          "service_life": formData.service_life || 20,
          "desired_discharge_energy_mwh": formData.desired_discharge_energy_mwh || null
        });
        setShowResults(true);
        if (toastContent.showToast) {
          updateToastContent({
            ...toastContent,
            showToast: false
          })
        }
        setLoading(false);
        let projectSiteData = data;
        if (params.visualizationParams['apv-env'] &&
          params.visualizationParams['apv-area'] &&
          params.visualizationParams['apv-density'] &&
          params.visualizationParams['apv-augmentation'] &&
          params.visualizationParams['apv-cam-pos'] &&
          params.visualizationParams['apv-cam-target'] &&
          params.visualizationParams['apv-cam-rot']
        ) {
          projectSiteData = { ...data, ...params.visualizationParams }
        }
        const postMessageData = { "action": "__PROJECT_SITE_DATA__", "data": projectSiteData }
        console.log(postMessageData);
        document.getElementById("visualizationIFrame").contentWindow.postMessage(postMessageData, "*");
      })
      .catch((e) => {
        console.error(e)
        updateToastContent({
          ...toastContent,
          toastMessage: e.error || "Internal Server Error",
          showToast: true
        })
        setLoading(false);
      })
  }

  return (
    <div className={`SubmissionForm ${showConfig ? "" : "slide-left"}`}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img className="pdfLogo" src={AESI4PDF} alt="AESI LOGO" style={{ display: "none", width: "60%", margin: "auto" }} />
        {!showResults ?
          null :
          closedContainer ?
            <>
              <h1 className="text-blue">Tera<span className="text-red">Stor</span>™</h1>
              <img className="terastor-img" src={teraStorClosed} alt="terastor closed doors" />
            </> :
            <>
              <h1 className="text-blue">Tera<span className="text-red">Stor</span>™</h1>
              <img className="terastor-img" src={teraStor} alt="terastor open doors" />
            </>}
        <Loader isSpinning={loading} />
        <form onSubmit={e => e.preventDefault(e)}>
          <div className={`SubmissionForm__section`}>
            <SubmissionFormBasic
              formData={formData}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleToggleChange={handleToggleChange}
            />
          </div>

        </form>
      </div>
      <div className="sticky-submit" style={{ position: "sticky" }}>
        <div className="submit-section" >
          <p className="refreshTip" style={{ fontSize: '10px', marginTop: 'auto', marginBottom: 'auto' }}>to reset form, press f5 or refresh</p>
          {showResults ?
            (formData.rated_system_power_mw && formData.desired_discharge_energy_mwh && formData.discharge_duration && formData.discharge_duration >= 2) ?
              <button id="submissionButton" type="button" onClick={(e) => getSystemData(e)} className={showOverlay ? "red" : "outline"}>Update Configuration</button>
              : <Tippy content="Rated System Power and Energy Capacity are required. Discharge Duration must be at least 2hrs."><button onClick={(e) => e.preventDefault()} className="disabled">Update Configuration</button></Tippy>
            : (formData.rated_system_power_mw && formData.desired_discharge_energy_mwh && formData.discharge_duration && formData.discharge_duration >= 2) ?
              <button id="submissionButton" type="button" onClick={(e) => getSystemData(e)}>SEE MY SYSTEM</button>
              : <Tippy content="Rated System Power and Energy Capacity are required. Discharge Duration must be at least 2hrs."><button className="disabled">SEE MY SYSTEM</button></Tippy>
          }
        </div>
        <div style={{ borderStyle: "dashed", borderWidth: 1, borderColor: '#ac141a' }}>
          <p style={{ fontSize: '12px', padding: 5, textAlign: 'left', color: '#ac141a' }}>This tool provides an estimate using the above basic assumptions. Please submit a “GET QUOTE” request to receive a customized plan to suit your exact needs.</p>
        </div>
      </div>

    </div>
  );
}