import FormInput from "../FormInput/FormInput";
import FormToggleSwitch from "../FormToggleSwitch/FormToggleSwitch";

export default function SubmissionFormBasic({ formData, closedContainer, handleChange, handleToggleChange, handleBlur }) {
  return (
    <>
      <FormInput
        label="Rated System Power"
        name="rated_system_power_mw"
        value={formData.rated_system_power_mw}
        type="text"
        required={true}
        handleChange={handleChange}
        handleBlur={handleBlur}
        tooltip="The rated power of the site"
        metric="MW (max: 10000)"
        placeholder="0.00"
      />

      <FormInput
        label="Discharge Duration"
        name="discharge_duration"
        value={formData.discharge_duration}
        type="text"
        handleChange={handleChange}
        handleBlur={handleBlur}
        tooltip="In hours (max: 24)"
        metric="hours (min: 2; max 24)"
        placeholder="4"
      />

      <FormInput
        label="Energy Capacity"
        name="desired_discharge_energy_mwh"
        value={formData.desired_discharge_energy_mwh}
        type="text"
        required={true}
        handleChange={handleChange}
        handleBlur={handleBlur}
        tooltip="Amount of dischargeable AC energy needed at POI (do not oversize). The configurator will calculate the correct size based on other requirements below."
        metric="MWh (max: 240000)"
        placeholder="400"
      />

      <FormToggleSwitch
        label="Energy Capacity Requirement"
        name="bol_eol_flag"
        value={formData.bol_eol_flag}
        type="select"
        options={[
          { label: "BOL", value: "BOL" },
          { label: "EOL", value: "EOL" },
        ]}
        handleChange={handleToggleChange}
        tooltip="Select BOL to specify capacity at beginning of life. Select EOL to specify minimum capacity throughout service life."
      />

      <FormToggleSwitch
        label="Augmentation"
        name="augmentation"
        value={formData.augmentation}
        type="checkbox"
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
        handleChange={handleToggleChange}
        tooltip="IF EOL selected, specify whether capacity to be added over time."
        hidden={formData.bol_eol_flag === "BOL"}
      />

      <FormInput
        label="Initial Augmentation Period"
        name="initial_augmentation_period"
        value={formData.initial_augmentation_period}
        type="text"
        handleChange={handleChange}
        handleBlur={handleBlur}
        tooltip="Time before the first augmentation"
        metric="years (min: 1; max:20)"
        placeholder="1"
        hidden={formData.bol_eol_flag === "BOL" || formData.augmentation === false}
      />

      <FormInput
        label="Augmentation Period"
        name="augmentation_period"
        value={formData.augmentation_period}
        type="text"
        handleChange={handleChange}
        handleBlur={handleBlur}
        tooltip="Time between each augmentation"
        metric="years (min: 1; max:20)"
        placeholder="3"
        hidden={formData.bol_eol_flag === "BOL" || formData.augmentation === false}
      />

      <FormInput
        label="Cycles per Year"
        name="cycles_per_year"
        value={formData.cycles_per_year}
        type="text"
        handleChange={handleChange}
        handleBlur={handleBlur}
        metric="(max: 730)"
        placeholder="365"
      />

      <FormInput
        label="Power Factor at POI"
        name="power_factor_support_at_poi"
        value={formData.power_factor_support_at_poi}
        type="text"
        handleChange={handleChange}
        handleBlur={handleBlur}
        tooltip="Customer required reactive power support"
        metric="(0.8-1.00)"
        placeholder="0.95"
      />

      <FormInput
        label="Service Life"
        name="service_life"
        value={formData.service_life}
        type="text"
        handleChange={handleChange}
        handleBlur={handleBlur}
        metric="years (max: 21)"
        placeholder="20"
      />

      <FormInput
        label="POI Voltage"
        name="poi_voltage"
        value={formData.poi_voltage}
        type="text"
        handleChange={handleChange}
        handleBlur={handleBlur}
        metric="kV (max: 1000)"
        placeholder="34.5"
      />

      <FormInput
        label="Delivery Date"
        name="delivery_date"
        value={formData.delivery_date}
        type="date"
        handleChange={handleChange}
      />
    </>
  )
}