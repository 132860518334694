import { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ResultsContext from '../../context/ResultsContext';
import SubmissionFormContext from '../../context/SubmissionFormContext';
import HubspotContactForm from './HubspotContactForm';
import './GetQuote.scss'

export default function GetQuote({ setShowContactForm }) {
  const REACT_APP_PROD_URL = process.env.REACT_APP_PROD_URL;
  const DEVELOPMENT_API = process.env.REACT_APP_DEVELOPMENT_API;
  const REACT_APP_PROD_API = process.env.REACT_APP_PROD_API;
  const [API, setAPI] = useState(DEVELOPMENT_API);

  const { formContent } = useContext(SubmissionFormContext);
  const { resultsContent } = useContext(ResultsContext);


  useEffect(() => {
    if (window.location.host === REACT_APP_PROD_URL) {
      setAPI(REACT_APP_PROD_API)
    }
  }, [REACT_APP_PROD_URL, REACT_APP_PROD_API]);


  useEffect(() => {
    setTimeout(() => {
      document.querySelector("input[name='aesi_terastor_sizing__estimate']").focus();
      document.querySelector("input[name='power_capacity']").value = formContent.rated_system_power_mw;
      document.querySelector("input[name='power_capacity']").focus();
      document.querySelector("input[name='energy_capacity']").value = formContent.desired_discharge_energy_mwh;
      document.querySelector("input[name='energy_capacity']").focus();
      document.querySelector("input[name='required_discharge_time']").value = formContent.discharge_duration;
      document.querySelector("input[name='required_discharge_time']").focus()
      document.querySelector("input[name='power_factor__poi']").value = formContent.power_factor_support_at_poi;
      document.querySelector("input[name='power_factor__poi']").focus();
      document.querySelector("input[name='expected_cycles_year']").value = formContent.cycles_per_year;
      document.querySelector("input[name='expected_cycles_year']").focus();
      document.querySelector("input[name='project_lifetime__years_']").value = formContent.service_life;
      document.querySelector("input[name='project_lifetime__years_']").focus();
      document.querySelector("input[name='poi_voltage__kv_']").value = formContent.poi_voltage;
      document.querySelector("input[name='poi_voltage__kv_']").focus();

      document.querySelector("input[name='orientation_information']").value = window.rotation;
      document.querySelector("input[name='orientation_information']").focus();
      document.querySelector("input[name='center_coordinates_lat']").value = window.centerCoordinates?.split(",")[0];
      document.querySelector("input[name='center_coordinates_lat']").focus();
      document.querySelector("input[name='center_coordinates_long']").value = window.centerCoordinates?.split(",")[1];
      document.querySelector("input[name='center_coordinates_long']").focus();
      document.querySelector("input[name='north_east_coordinates_lat']").value = window.northEastCoordinates?.split(",")[0];
      document.querySelector("input[name='north_east_coordinates_lat']").focus();
      document.querySelector("input[name='north_east_coordinates_long']").value = window.northEastCoordinates?.split(",")[1];
      document.querySelector("input[name='north_east_coordinates_long']").focus();
      document.querySelector("input[name='south_west_coordinates_lat']").value = window.southWestCoordinates?.split(",")[0];
      document.querySelector("input[name='south_west_coordinates_lat']").focus();
      document.querySelector("input[name='south_west_coordinates_long']").value = window.southWestCoordinates?.split(",")[1];
      document.querySelector("input[name='south_west_coordinates_long']").focus();
      document.querySelector("input[name='vizualization_width']").value = window.width;
      document.querySelector("input[name='vizualization_width']").focus();
      document.querySelector("input[name='vizualization_height']").value = window.height;
      document.querySelector("input[name='vizualization_height']").focus();
      document.querySelector("input[name='configurator_location_file']").value = window.kmlFile;
      document.querySelector("input[name='configurator_location_file']").focus();

      // select the EOL/BOL flag
      if (formContent.bol_eol_flag == "EOL") {
        document.querySelector("select[name='energy_capacity_maintenance_required_']").value = "Yes - EOL";
        document.querySelector("select[name='energy_capacity_maintenance_required_']").dispatchEvent(new Event('change', { 'view': window, 'bubbles': true }));

        // wait for Augmentation fields to appear
        setTimeout(() => {
          if (formContent.augmentation == true) {
            document.querySelectorAll("input[name='augmentation_required_']")[0].checked = true;
          } else if (formContent.augmentation == false) {
            document.querySelectorAll("input[name='augmentation_required_']")[1].checked = true;
          }
          document.querySelector("input[name='time_before_first_augmentation']").value = formContent.initial_augmentation_period;
          document.querySelector("input[name='time_before_first_augmentation']").focus();
          document.querySelector("input[name='augmentation_period_thereafter']").value = formContent.augmentation_period;
          document.querySelector("input[name='augmentation_period_thereafter']").focus();
        }, 500)
      } else if (formContent.bol_eol_flag == "BOL") {
        document.querySelector("select[name='energy_capacity_maintenance_required_']").value = "No";
        document.querySelector("select[name='energy_capacity_maintenance_required_']").focus();
      }

      if (window.coordinates) {
        document.querySelector("select[name='location_submission']").value = "Coordinates";
        document.querySelector("select[name='location_submission']").focus();
        document.querySelector("select[name='location_submission']").dispatchEvent(new Event('change', { 'view': window, 'bubbles': true }));

        setTimeout(() => {
          document.querySelector("input[name='coordinates']").value = window.coordinates;
          document.querySelector("input[name='coordinates']").focus();
        }, 500)
      }

      // energy capacity maint req
      // augmentation

      document.querySelector("input[name='requested_response_date']").autocomplete = "off";
      document.querySelector("input[name='project_name']").autocomplete = "new-password";
      document.querySelector("select[name='project_type']").autocomplete = "off";
      document.querySelector("input[name='project_commercial_operation_date__cod_']").autocomplete = "off";
      document.querySelector("select[name='incoterms']").autocomplete = "off";
      document.querySelector("select[name='capacity_assurance_time']").autocomplete = "off";
      document.querySelector("select[name='pcs']").autocomplete = "off";
      document.querySelector("select[name='location_submission']").autocomplete = "new-password";
      document.querySelector("input[name='site_footprint']").autocomplete = "off";
      document.querySelector("input[name='project_elevation']").autocomplete = "off";
      document.querySelector("textarea[name='additional_project_requirements']").autocomplete = "off";
      document.querySelector("input[name='power_capacity']").autocomplete = "new-password";
      document.querySelector("input[name='energy_capacity']").autocomplete = "new-password";
      document.querySelector("input[name='required_discharge_time']").autocomplete = "off";
      document.querySelector("input[name='power_factor__poi']").autocomplete = "off";
      document.querySelector("input[name='expected_cycles_year']").autocomplete = "new-password";
      document.querySelector("input[name='project_lifetime__years_']").autocomplete = "new-password";
      document.querySelector("select[name='energy_capacity_maintenance_required_']").autocomplete = "new-password";
      document.querySelector("input[name='time_before_first_augmentation']").autocomplete = "off";
      document.querySelector("input[name='augmentation_period_thereafter']").autocomplete = "off";
      document.querySelector("input[name='poi_voltage__kv_']").autocomplete = "new-password";


      // focus back on first field
      setTimeout(() => {
        document.querySelector("input[name='firstname']").focus();
      }, 501);

    }, 750)
  }, [])

  return (
    <>
      <div className="modal-overlay"></div>
      <div className="form-modal card-basic">
        <div onClick={() => setShowContactForm(false)} className="close-icon text-blue"><FontAwesomeIcon icon={faXmark} /></div>
        <>
          <h2 className="text-blue">Fill Out This Form for a Quote</h2>
          <HubspotContactForm
            region="na1"
            portalId="45669076"
            formId="33a306d9-440f-4de0-839d-6372fcf294ed"
            sfdcCampaignId="701VM000005KYViYAO"
          />
        </>
      </div>
    </>
  )
}